/* eslint-disable react-hooks/exhaustive-deps */
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MessageServices } from "../../shared/services/message/message.service";

const TokenInterceptor = () => {
  const navigate = useNavigate();
  const messageService = new MessageServices();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const isSignupRoute = location.pathname === "/signup";
    const isForgotPassword = location.pathname === "/forgot-password";
    const isResetPassword = location.pathname.startsWith("/reset-password");
    if (isSignupRoute || isForgotPassword || isResetPassword) return;
    if (!token) {
      navigate("/login");
    } else {
      if (!token) return;
      const decodedToken: any = jwtDecode(token);
      if (decodedToken?.exp < Date.now() / 1000) {
        localStorage.removeItem("token");
        messageService.error(
          "Sessão expirada",
          "Sua sessão expirou. Por favor, faça o login novamente."
        );
        navigate("/login");
      }
    }
  }, [navigate]);

  return <></>;
};

export default TokenInterceptor;

import styled from "styled-components";
export const ForgotPasswordContainer = styled.div`
  .back-to-login {
    font-size: 0.9rem !important;
    color: black;
    transition: 0.25s;
    cursor: pointer;
  }

  .back-to-login:hover {
    color: #3eff9f;
  }
`;

import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import { Button, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RingLoader } from "react-spinners";
import logo from "../../assets/svg/LogoFineAndYou_login.svg";
import {
  BottonBall,
  TopBall,
} from "../../shared/components/templates/main-container/main-container";
import AuthService from "../../shared/services/auth/auth.service";
import { MessageServices } from "../../shared/services/message/message.service";

const ResetPassword: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const authService = new AuthService();
  const messageService = new MessageServices();
  const isMobile = useMediaQuery("(max-width: 768px)"); 

  const validatePasswords = () => {
    if (!newPassword || !confirmPassword) {
      setPasswordError("Os campos de senha são obrigatórios.");
      return false;
    }
    if (newPassword !== confirmPassword) {
      setPasswordError("As senhas não correspondem.");
      return false;
    }
    const validations = [
      newPassword.length >= 8,
      /[A-Z]/.test(newPassword),
      /[a-z]/.test(newPassword),
      /\d/.test(newPassword),
    ];
    if (!validations.every((v) => v)) {
      setPasswordError(
        "A senha deve conter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula e um número."
      );
      return false;
    }
    setPasswordError("");
    return true;
  };

  const handleResetPassword = async () => {
    if (!validatePasswords()) return;

    setLoading(true);
    try {
      await authService.resetPassword(token || "", newPassword);
      messageService.success("Sucesso", "Senha redefinida com sucesso!");
      navigate("/login");
    } catch (error: any) {
      messageService.error(
        "Erro",
        `Não foi possível redefinir a senha: ${error.message}`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="d-flex animate__animated animate__fadeIn p-5 d-flex justify-content-center align-items-center"
      style={{ width: "100dvw", height: "100dvh", overflowX: "hidden" }}
    >
      <TopBall />
      <BottonBall />
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RingLoader color="#00df70" loading={true} />
        </div>
      )}
      <form
        style={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}
        className={`form h-100 p-5 ${
          isMobile ? "w-100" : "w-40"
        } d-flex flex-column justify-content-center`}
        onSubmit={(e) => {
          e.preventDefault();
          handleResetPassword();
        }}
      >
        <div className="d-flex mb-5 justify-content-center align-items-center">
          <img src={logo} width={132} height={140} alt="Logo" />
        </div>
        <Typography variant="h4" className="mb-4">
          Redefinir Senha
        </Typography>
        <TextField
          size="small"
          className={`w-100 mt-4`}
          id="new-password-field"
          label="Nova Senha"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <div className="d-flex flex-column mt-3">
          {[/[A-Z]/, /[a-z]/, /\d/].map((regex, index) => (
            <div key={index} className="d-flex align-items-center">
              {regex.test(newPassword) ? (
                <CheckCircleOutline sx={{ color: "green" }} />
              ) : (
                <ErrorOutline sx={{ color: "red" }} />
              )}
              <Typography variant="body2" className="ms-2">
                {index === 0
                  ? "Pelo menos uma letra maiúscula"
                  : index === 1
                  ? "Pelo menos uma letra minúscula"
                  : "Pelo menos um número"}
              </Typography>
            </div>
          ))}
          <div className="d-flex align-items-center">
            {newPassword.length >= 8 ? (
              <CheckCircleOutline sx={{ color: "green" }} />
            ) : (
              <ErrorOutline sx={{ color: "red" }} />
            )}
            <Typography variant="body2" className="ms-2">
              Pelo menos 8 caracteres
            </Typography>
          </div>
        </div>
        <TextField
          size="small"
          className={`w-100 mt-4`}
          id="confirm-password-field"
          label="Confirmar Nova Senha"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {passwordError && (
          <Typography
            variant="body2"
            className="text-danger mt-2"
            style={{ color: "red" }}
          >
            {passwordError}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="mt-5 w-100"
        >
          Redefinir Senha
        </Button>
      </form>
    </div>
  );
};

export default ResetPassword;

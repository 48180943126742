import { Button, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RingLoader } from "react-spinners";
import logo from "../../assets/svg/LogoFineAndYou_login.svg";
import {
  BottonBall,
  TopBall,
} from "../../shared/components/templates/main-container/main-container";
import AuthService from "../../shared/services/auth/auth.service";
import { MessageServices } from "../../shared/services/message/message.service";
import { ForgotPasswordContainer } from "./style";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const authService = new AuthService();
  const messageService = new MessageServices();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const validateEmail = () => {
    if (!email) {
      setEmailError("Campo E-mail é obrigatório");
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Insira um e-mail válido");
      return false;
    }
    setEmailError("");
    return true;
  };

  const handleForgotPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateEmail()) return;

    setLoading(true);
    try {
      await authService.sendResetPasswordEmail(email);
      messageService.success(
        "Sucesso",
        "Verifique seu e-mail para redefinir a senha"
      );
      navigate("/login");
    } catch (error: any) {
      messageService.error(
        "Erro",
        `Não foi possível processar a solicitação: ${error.message}`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <ForgotPasswordContainer
      className="d-flex animate__animated animate__fadeIn p-5 d-flex justify-content-center align-items-center"
      style={{ width: "100dvw", height: "100dvh", overflowX: "hidden" }}
    >
      <TopBall />
      <BottonBall />
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RingLoader color="#00df70" loading={true} />
        </div>
      )}
      <form
        onSubmit={handleForgotPassword}
        className={`form h-100 p-5 ${
          isMobile ? "w-100" : "w-40"
        } d-flex flex-column justify-content-center align-items-center`}
        style={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="d-flex mb-5 justify-content-center align-items-center">
          <img src={logo} width={132} height={140} alt="Logo" />
        </div>
        <Typography variant="h4" className="text-center mb-4">
          Esqueci Minha Senha
        </Typography>
        <TextField
          size="small"
          className={`w-100 ${emailError ? "error" : ""}`}
          id="email-field"
          label="E-mail"
          variant="outlined"
          error={!!emailError}
          helperText={<small className="text-danger">{emailError}</small>}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          type="submit"
          className="submit-button w-100 mt-4"
          variant="contained"
          color="primary"
        >
          Enviar
        </Button>
        <div
          onClick={() => navigate("/login")}
          className="d-flex justify-content-center align-items-center mt-3"
        >
          <a className="back-to-login">Voltar ao Login</a>
        </div>
      </form>
    </ForgotPasswordContainer>
  );
};

export default ForgotPassword;

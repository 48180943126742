import { requestService } from "../../../core/services/request.service";
import DELETE_ACCOUNT_MUTATION from "./mutations/deleteAccount.mutation";
import LOGIN_MUTATION from "./mutations/login.mutation";
import RESET_PASSWORD_MUTATION from "./mutations/reset-password.mutation";
import SEND_RESET_PASSWORD_EMAIL_MUTATION from "./mutations/send-reset-password-email.mutation";
import CREATE_USER_MUTATION from "./mutations/signup.mutation";
import UPDATE_PASSWORD_MUTATION from "./mutations/updatePassword.mutation";
import GET_USER from "./queries/getUser.query";

class AuthService {
  async login(email: string, password: string) {
    try {
      const { data } = await requestService.mutate(LOGIN_MUTATION, {
        email,
        password,
      });
      return data.login;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async updatePassword(oldPassword: string, password: string) {
    try {
      const { data } = await requestService.mutate(UPDATE_PASSWORD_MUTATION, {
        password,
        oldPassword,
      });
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async resetPassword(token: string, newPassword: string): Promise<string> {
    try {
      const { data } = await requestService.mutate(RESET_PASSWORD_MUTATION, {
        token,
        newPassword,
      });
      return data.resetPassword;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async sendResetPasswordEmail(email: string): Promise<string> {
    try {
      const { data } = await requestService.mutate(
        SEND_RESET_PASSWORD_EMAIL_MUTATION,
        { email }
      );
      return data.sendResetPasswordEmail;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async deleteAccount() {
    try {
      const { data } = await requestService.mutate(DELETE_ACCOUNT_MUTATION, {});
      return data;
    } catch (error: any) {
      console.log(error);
    }
  }

  async createUser(
    name: string,
    email: string,
    password: string,
    phoneNumber: string | null
  ) {
    const { data } = await requestService.mutate(CREATE_USER_MUTATION, {
      name,
      email,
      password,
      phoneNumber,
    });
    return data?.createUser;
  }

  async getUser(): Promise<UserOutput | null> {
    try {
      const result = await requestService.getAll(GET_USER);
      return result.data.getUser;
    } catch (error: any) {
      console.log(error.message);
      return null;
    }
  }
}

export type UserOutput = {
  _id: string;
  id: string;
  name: string;
  email: string;
  password: string;
  createdAt: Date;
  dateSubmitted: Date;
  phoneNumber: string;
  isContentCreator: boolean;
};
export default AuthService;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RingLoader } from "react-spinners";
import logo from "../../../assets/svg/LogoFineAndYou_login.svg";
import AuthService from "../../services/auth/auth.service";
import { MessageServices } from "../../services/message/message.service";
import PasswordToggle from "./components/passwordToggle";
import { LeftSideLogin, RightSideLogin } from "./style";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);

  const authService = new AuthService();
  const messageService = new MessageServices();
  const navigate = useNavigate();

  const validateEmail = () => {
    if (!email) {
      setEmailError("Campo E-mail é obrigatório");
      return false;
    }
    setEmailError("");
    return true;
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError("Campo senha é obrigatório");
      return false;
    }
    setPasswordError("");
    return true;
  };

  const handleLogin = async () => {
    const isEmailValid = validateEmail();
    const isPasswordValid = validatePassword();

    if (isEmailValid && isPasswordValid) {
      try {
        setLoading(true);
        const result = await authService.login(email, password);
        if (result) {
          localStorage.setItem("token", result);
          navigate("/dashboard");
        }
      } catch (error) {
        messageService.error("Erro", "Falha ao autenticar");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className="d-flex w-100 h-100 ">
      <LeftSideLogin />
      <RightSideLogin className="animate__animated animate__fadeIn">
        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              zIndex: 1000000,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RingLoader color="#00df70" loading={true} />
          </div>
        )}
        <form autoComplete="off" className="form">
          <div className="d-flex mb-5 justify-content-center align-items-center">
            <img src={logo} width={132} height={140} alt="Logo" />
          </div>
          <TextField
            size="small"
            className={`w-100 ${emailError ? "error" : ""}`}
            type="email"
            autoComplete="new-password"
            id="email-field"
            label="E-mail"
            variant="outlined"
            error={!!emailError}
            helperText={<small className="text-danger">{emailError}</small>}
            inputProps={{
              className: "email-field",
              value: email,
              onChange: (e: any) => {
                setEmail(e.target.value);
                validateEmail();
                validatePassword();
              },
              onBlur: (e: any) => {
                setEmail(e.target.value);
                validateEmail();
                validatePassword();
              },
              onKeyPress: handleKeyPress,
            }}
          />
          <TextField
            size="small"
            className={`w-100 mt-4 ${passwordError ? "error" : ""}`}
            id="password-field"
            label="Senha"
            autoComplete="new-password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            error={!!passwordError}
            helperText={<small className="text-danger">{passwordError}</small>}
            InputProps={{
              className: "password-field",
              endAdornment: (
                <PasswordToggle
                  showPassword={showPassword}
                  togglePassword={() => setShowPassword(!showPassword)}
                />
              ),
              value: password,
              onChange: (e) => {
                setPassword(e.target.value);
                validateEmail();
                validatePassword();
              },
              onBlur: (e) => {
                setPassword(e.target.value);
                validateEmail();
                validatePassword();
              },
              onKeyPress: handleKeyPress,
            }}
          />
          <button
            type="button"
            className="submit-button w-100 mt-5 px-3 py-2"
            onClick={handleLogin}
          >
            Entrar
          </button>
          <div className="d-flex align-self-start flex-column align-items-start">
            <div
              onClick={() => {
                navigate("/signup");
              }}
              className="d-flex align-items-center mt-3"
            >
              Não possui uma conta?
              <a className="forgot-passoword m-0 ms-2">Cadastre-se</a>
            </div>
            <div
              onClick={() => {
                navigate("/forgot-password");
              }}
              className="d-flex align-items-center mt-3"
            >
              Esqueceu a Senha?
              <a className="forgot-passoword m-0 ms-2">Clique Aqui</a>
            </div>
          </div>
        </form>
      </RightSideLogin>
    </div>
  );
};

export default Login;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material"; // Importar os ícones
import { TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { IMaskInput } from "react-imask";
import { useNavigate } from "react-router-dom";
import { RingLoader } from "react-spinners";
import logo from "../../assets/svg/LogoFineAndYou_login.svg";
import {
  BottonBall,
  TopBall,
} from "../../shared/components/templates/main-container/main-container";
import AuthService from "../../shared/services/auth/auth.service";
import { MessageServices } from "../../shared/services/message/message.service";
import { SignUpStyle } from "./style";

interface SignupProps {}
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(00) 00000-0000"
        definitions={{
          "#": /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

const Signup: React.FC<SignupProps> = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const authService = new AuthService();
  const messageService = new MessageServices();

  const navigate = useNavigate();

  const validateName = () => {
    if (!name) {
      setNameError("Campo Nome é obrigatório");
      return false;
    }
    setNameError("");
    return true;
  };

  const validateEmail = () => {
    if (!email) {
      setEmailError("Campo E-mail é obrigatório");
      return false;
    }
    setEmailError("");
    return true;
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError("Campo senha é obrigatório");
      return false;
    }
    const validations = [
      password.length >= 8,
      /[A-Z]/.test(password),
      /[a-z]/.test(password),
      /\d/.test(password),
    ];
    const isValid = validations.every((validation) => validation);
    if (!isValid) {
      setPasswordError(
        "Senha deve conter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula e um número"
      );
      return false;
    }
    setPasswordError("");
    return true;
  };

  const validateConfirmPassword = () => {
    if (password !== confirmPassword) {
      setConfirmPasswordError("As senhas não coincidem");
      return false;
    }
    setConfirmPasswordError("");
    return true;
  };

  const handleSignup = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    try {
      const isNameValid = validateName();
      const isEmailValid = validateEmail();
      const isPasswordValid = validatePassword();
      const isConfirmPasswordValid = validateConfirmPassword();

      if (
        isNameValid &&
        isEmailValid &&
        isPasswordValid &&
        isConfirmPasswordValid
      ) {
        await authService.createUser(name, email, password, phone);
        messageService.success("Sucesso", "Usuário criado com sucesso");
        navigate("/login");
      } else {
        messageService.error("Erro", "Validação dos dados falhou");
      }
    } catch (error: any) {
      messageService.error(
        "Erro",
        `Não foi possível criar o usuário: ${error.message}`
      );
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="d-flex animate__animated animate__fadeIn p-5 d-flex justify-content-center align-items-center"
      style={{ width: "100dvw", height: "100dvh", overflowX: "hidden" }}
    >
      <TopBall />
      <BottonBall />
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RingLoader color="#00df70" loading={true} />
        </div>
      )}
      <SignUpStyle.Form
        onSubmit={handleSignup}
        autoComplete="off"
        className="form h-100 p-5 w-40"
      >
        <div className="d-flex mb-5 justify-content-center align-items-center">
          <img src={logo} width={132} height={140} alt="Logo" />
        </div>
        <TextField
          size="small"
          className={`w-100 ${nameError ? "error" : ""}`}
          id="name-field"
          label="Nome"
          variant="outlined"
          error={!!nameError}
          helperText={<small className="text-danger">{nameError}</small>}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          size="small"
          className={`w-100 mt-4 ${emailError ? "error" : ""}`}
          type="email"
          id="email-field"
          label="E-mail"
          variant="outlined"
          role="presentation"
          error={!!emailError}
          helperText={<small className="text-danger">{emailError}</small>}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          size="small"
          className={`w-100 mt-4 ${phoneError ? "error" : ""}`}
          id="phone-field"
          label="Telefone"
          variant="outlined"
          error={!!phoneError}
          helperText={<small className="text-danger">{phoneError}</small>}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          InputProps={{
            inputComponent: TextMaskCustom as any,
          }}
        />
        <TextField
          size="small"
          className={`w-100 mt-4`}
          id="password-field"
          label="Senha"
          autoComplete="new-password"
          variant="outlined"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="d-flex flex-column mt-3">
          {[/[A-Z]/, /[a-z]/, /\d/].map((regex, index) => (
            <div key={index} className="d-flex align-items-center">
              {regex.test(password) ? (
                <CheckCircleOutline sx={{ color: "green" }} />
              ) : (
                <ErrorOutline sx={{ color: "red" }} />
              )}
              <Typography variant="body2" className="ms-2">
                {index === 0
                  ? "Pelo menos uma letra maiúscula"
                  : index === 1
                  ? "Pelo menos uma letra minúscula"
                  : "Pelo menos um número"}
              </Typography>
            </div>
          ))}
          <div className="d-flex align-items-center">
            {password.length >= 8 ? (
              <CheckCircleOutline sx={{ color: "green" }} />
            ) : (
              <ErrorOutline sx={{ color: "red" }} />
            )}
            <Typography variant="body2" className="ms-2">
              Pelo menos 8 caracteres
            </Typography>
          </div>
        </div>
        <TextField
          size="small"
          className={`w-100 mt-4 ${confirmPasswordError ? "error" : ""}`}
          id="confirm-password-field"
          label="Confirmar Senha"
          variant="outlined"
          type={showPassword ? "text" : "password"}
          error={!!confirmPasswordError}
          helperText={
            <small className="text-danger">{confirmPasswordError}</small>
          }
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button type="submit" className="submit-button w-100 mt-5 px-3 py-2">
          Cadastrar
        </button>
        <div
          onClick={() => {
            navigate("/login");
          }}
          className="d-flex align-items-center mt-3"
        >
          Já possui uma conta?
          <a className="forgot-passoword m-0 ms-2">Faça Login</a>
        </div>
      </SignUpStyle.Form>
    </div>
  );
};

export default Signup;
